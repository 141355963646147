import { useEffect, useRef, useState } from "react";
import { Pane, SeriesSearchIcon } from "evergreen-ui";
import * as Sentry from "@sentry/react";
import { Store } from "../../../common/models";
import { CenteredSpinner } from "../common/CenteredSpinner";
import { EmptyState } from "../common/EmptyState";
import { fetchPayoutDashboard, PayoutDashboardResponse } from "../datastore/PayoutsDataStore";
import { TableauViz, TableauEventType } from '@tableau/embedding-api';
import { useMobileMediaQuery } from "../common/MediaQuery";

interface PayoutsTabProps {
    selectedIndex: number;
    tabIndex: number;
    store: Store;
}

export const PayoutsTab = (props: PayoutsTabProps) => {
    const { selectedIndex, tabIndex, store } = props;

    const isMobile = useMobileMediaQuery();

    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<boolean>(false);
    const vizRef = useRef<TableauViz | null>(null);

    useEffect(() => {
        if (selectedIndex !== tabIndex) {
            // This instance is not currently selected.
            return;
        }

        if (!loading) {
            // The dashboard URL has already been loaded.
            return;
        }

        // This instance is now active, so fetch the dashboard URL.
        fetchPayoutDashboard(store.id).then((data: PayoutDashboardResponse) => {
            initializeTableau(data.dashboardUrl, data.jwt);
        })
            .catch((error) => {
                Sentry.captureException(error);
                setError(true);
                setLoading(false);
            });

    }, [selectedIndex, tabIndex]);

    const initializeTableau = (dashboard_url: string, token: string) => {
        try {
            const viz = new TableauViz();

            viz.src = dashboard_url;
            viz.token = token;

            viz.disableVersionCheck = true;

            viz.hideTabs = true;
            viz.toolbar = "hidden" as any;

            if (window.innerWidth <= 768) {
                viz.touchOptimize = true;
            }

            viz.addEventListener(TableauEventType.FirstInteractive, async () => {
                const config = new Map<string, string>([
                    // ["color", "Blue"],
                ]);

                try {
                    await changeParameterValues(config);
                } catch (error) {
                    console.error("Error changing parameter values:", error);
                    Sentry.captureException(error);
                } finally {
                    setLoading(false);
                }
            });

            const vizParameter = document.createElement("viz-parameter");
            viz.appendChild(vizParameter);

            document.getElementById('tableau-dashboard')?.appendChild(viz);
            vizRef.current = viz;

        } catch (error) {
            console.error('Error initializing Tableau:', error);
            Sentry.captureException(error);
            setError(true);
            setLoading(false);
        }
    };

    const changeParameterValues = async (config: Map<string, string>): Promise<void> => {
        if (!vizRef.current) {
            console.error("Viz reference is not available");
            return;
        }

        const workbook = vizRef.current.workbook;

        const changePromises = Array.from(config).map(([key, value]) => {
            return workbook.changeParameterValueAsync(key, value)
                .then(() => {
                    console.log(`Parameter '${key}' value changed successfully to '${value}'`);
                })
                .catch((error) => {
                    console.error(`Error changing parameter '${key}' value:`, error);
                    Sentry.captureException(error);
                });
        });

        await Promise.all(changePromises);
    };

    const renderLoadingState = () => {
        // The loading spinner must be mounted overtop
        // to be displayed until tableau is done loading
        return (loading && !error) && (
            <Pane
                position="absolute"
                top={0}
                left={0}
                right={0}
                bottom={0}
                backgroundColor="white"
                zIndex={1000}
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <CenteredSpinner />
            </Pane>
        );
    };

    const renderDashboardOrErrorState = () => {
        return error ? (
            <EmptyState
                title={"Payout Reports Coming Soon."}
                subtitle={" "}
            >
                <SeriesSearchIcon size={60} />
            </EmptyState>
        ) : null;
    };

    return (
        <Pane
            width={"100%"}
            position={"relative"}
            height={"100%"}
            overflowY={"hidden"}
            overflowX={"auto"}
            id={`panel-${store.name}`}
            role="tabpanel"
            key={`panel-${store.name}`}
            aria-labelledby={store.name}
            aria-hidden={tabIndex !== selectedIndex}
            display={(tabIndex === selectedIndex) ? 'block' : 'none'}
        >
            {renderLoadingState()}
            <div id="tableau-dashboard" style={{ height: '100%', width: isMobile ? '100%' : '1366px', overflow: 'auto' }}>
                {renderDashboardOrErrorState()}
            </div>
        </Pane>
    );
};
